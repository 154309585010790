import { formatRate, IconStar, pluralize } from '@leland-dev/leland-ui-library';
import { type FC } from 'react';

import { AVG_REVIEW_RATE, NUM_CUSTOMERS_STR } from '../../utils/constants';
import { CategoryCta } from '../CategoryCta';

import { OutcomesMarquee } from './OutcomesMarquee';

export const Hero: FC<{ totalReviews: number }> = ({ totalReviews }) => (
  <section className="mx-auto flex w-full flex-col justify-center text-center">
    <header className="space-y-5 px-6 sm:space-y-4 ">
      <h2 className="font-condensed text-5xl font-medium">
        Reach your most ambitious goals.
      </h2>
      <h3 className="text-xl text-leland-gray-light">
        Access coaching, courses, and content powered by thousands of career and
        admissions experts.
      </h3>
    </header>
    <div className="flex flex-col items-center justify-center px-6">
      <CategoryCta />
    </div>
    <div className="mt-20 flex flex-col items-center justify-center px-4 text-sm uppercase tracking-wider text-leland-gray-light sm:mt-32 md:flex-row">
      <p>
        JOIN {NUM_CUSTOMERS_STR} Real people achieving their goals on Leland
      </p>
      <p className="hidden px-2 md:block"> &middot; </p>
      <div className="flex items-center gap-1">
        <IconStar className="size-4 text-leland-yellow" />
        <p>
          {pluralize(totalReviews, 'review', { useLocale: true })}{' '}
          <span className="text-leland-gray-extra-light">
            (AVG {formatRate(AVG_REVIEW_RATE)})
          </span>
        </p>
      </div>
    </div>

    <div className="mt-6">
      <OutcomesMarquee />
    </div>
  </section>
);
