import isEqual from 'lodash/isEqual';
import {
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { filterSearch } from '../../../utils/filterSearch';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import SvgIcon, { Svg } from '../../SvgIcon';
import LoadingIndicator from '../../common/LoadingIndicator';
import { ErrorMessage } from '../ErrorMessage';

import {
  type CommonSelectProps,
  createableLabel,
  DEFAULT_NO_OPTIONS_LABEL,
} from '.';

export type SelectProps<Value> = CommonSelectProps<Value> & {
  onChange: (value: Nullable<Value>) => void;
  value: Nullable<Value>;
};

const Select = <Value,>({
  className = '',
  label,
  labelClassName = '',
  labelIsHidden,
  subLabel,
  flag,
  placeholder = 'Select an option...',
  options,
  loading,
  onChange,
  value,
  renderOption,
  error,
  disabled,
  // searchable props
  isSearchable,
  preventFilterSearch,
  onInputChange,
  createProps,
  required = false,
  customErrorMessage = required ? 'This is required' : undefined,
}: SelectProps<Value>): ReactElement => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [input, setInputDirect] = useState<string>('');
  const query = useDebounce(input, 100);

  const [validationError, setValidationError] = useState<boolean>(false);

  const setInput = useCallback(
    (value: string) => {
      setInputDirect(value);
      onInputChange?.(value);
    },
    [onInputChange],
  );

  const openInput = () => {
    setIsOpen(true);
    inputRef.current?.focus();
  };

  const closeInput = useCallback(() => {
    setIsOpen(false);
    setInput('');
    inputRef.current?.blur();
  }, [setInput]);

  const selectedOption = useMemo(() => {
    if (value) {
      return options.find((opt) => isEqual(opt.value, value));
    }
  }, [options, value]);

  const filteredOptions = useMemo(() => {
    if (!preventFilterSearch && query) {
      return filterSearch(query, options, ['label']);
    }
    return options;
  }, [query, options, preventFilterSearch]);

  useEffect(() => {
    if (isOpen) {
      const handleClick = (e: MouseEvent) => {
        if (anchorRef.current?.contains(e.target as Node)) {
          return;
        }
        closeInput();
      };
      document.addEventListener('click', handleClick);

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          closeInput();
        }
      };
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [closeInput, isOpen]);

  return (
    <div className={`${className} relative`}>
      {!labelIsHidden ? (
        <label className={`mb-1 ${labelClassName}`}>
          <div>
            <span className="text-sm font-medium text-gray-900">{label}</span>
            {flag ? (
              <span className="text-xs text-gray-500">
                &nbsp;&bull;&nbsp;{flag}
              </span>
            ) : null}
          </div>
          {subLabel ? (
            <p className="text-sm font-normal text-leland-gray-dark">
              {subLabel}
            </p>
          ) : null}
        </label>
      ) : null}
      <div ref={anchorRef} className="h-full">
        <button
          type="button"
          className={`flex size-full min-w-55 cursor-pointer select-none items-center gap-1 whitespace-nowrap rounded-lg bg-white px-4 py-3 text-left shadow-none transition duration-100 focus-within:shadow-sm hover:bg-leland-gray-hover ${
            disabled ? 'pointer-events-none cursor-not-allowed opacity-50' : ''
          } ${isOpen ? 'bg-leland-gray-hover' : ''}`}
          // if searchable, ensure all clicks on button focus the input instead of blurring it
          onClick={isOpen ? (isSearchable ? undefined : closeInput) : openInput}
          disabled={disabled}
        >
          <div className="flex w-full items-center justify-between">
            {isSearchable ? (
              <SvgIcon
                svgType={Svg.SEARCH}
                className="mr-2.5 text-leland-gray-extra-light"
              />
            ) : null}
            <div className="flex w-full items-center text-lg font-medium text-leland-gray-dark">
              {selectedOption && !isOpen ? (
                renderOption ? (
                  renderOption(selectedOption.value)
                ) : (
                  selectedOption.label
                )
              ) : (
                <input
                  ref={inputRef}
                  className={`w-full bg-transparent outline-none focus:outline-none ${
                    (!isOpen || !isSearchable) && 'cursor-pointer'
                  } ${
                    isOpen
                      ? 'placeholder:text-leland-gray-extra-light'
                      : 'placeholder:text-leland-gray-dark'
                  } ${disabled ? 'cursor-not-allowed' : ''}`}
                  placeholder={placeholder}
                  value={input}
                  onChange={(e) => {
                    setValidationError(false);
                    setInput(e.currentTarget.value);
                  }}
                  readOnly={!isSearchable || disabled}
                  required={required}
                  onInvalid={(e) => {
                    e.preventDefault();
                    setValidationError(true);
                  }}
                />
              )}
            </div>
          </div>
          <SvgIcon
            className="size-4 text-leland-gray-extra-light"
            svgType={Svg.CHEVRON}
          />
        </button>
        {isOpen ? (
          <ul className="absolute top-full z-10  mt-2 max-h-60 w-full overflow-auto rounded-lg border border-leland-gray-stroke bg-white p-2 shadow-lg">
            {loading ? (
              <LoadingIndicator iconClassName="w-6 h-6" />
            ) : filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li key={option.label}>
                  <button
                    type="button"
                    disabled={option.disabled}
                    className="flex w-full items-center justify-start rounded-md p-3 text-left text-base font-medium hover:bg-leland-gray-hover focus:bg-leland-gray-hover"
                    onClick={() => {
                      setValidationError(false);
                      onChange(option.value);
                      closeInput();
                    }}
                  >
                    {renderOption?.(option.value) ?? option.label}
                  </button>
                </li>
              ))
            ) : (
              <li
                className={`flex w-full items-center rounded-md p-2 ${
                  createProps && input
                    ? ''
                    : 'justify-center text-leland-gray-dark'
                }`}
              >
                {createProps && input ? (
                  <button
                    type="button"
                    className="w-full rounded-md p-3 text-left hover:bg-leland-gray-hover focus:bg-leland-gray-hover"
                    onClick={async () => {
                      const value = await createProps.onCreate(input);
                      onChange(value);
                      closeInput();
                    }}
                  >
                    {createableLabel(input, createProps.itemType)}
                  </button>
                ) : (
                  DEFAULT_NO_OPTIONS_LABEL
                )}
              </li>
            )}
          </ul>
        ) : null}
      </div>
      {(error ?? validationError) ? (
        <ErrorMessage errorMessage={error ?? customErrorMessage ?? ''} />
      ) : null}
    </div>
  );
};

export default Select;
